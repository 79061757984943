import type { JsonType } from "posthog-js";
import { v4 as uuidv4 } from "uuid";

// Function to obtain the distinctId based on the environment
export function getDistinctId(): string {
  const runtimeConfig = useRuntimeConfig();

  if (process.env.NODE_ENV === "development") {
    return uuidv4(); // Using UUID in development mode
  } else if (process.env.NODE_ENV === "production") {
    const cookieName = `ph_${runtimeConfig.public.posthogPublicKey}_posthog`;
    const cookies = useCookie<{ distinct_id: string }>(cookieName);

    if (cookies && cookies.value && cookies.value.distinct_id) {
      return cookies.value.distinct_id; // Using cookie in production
    } else {
      return uuidv4(); // Return uuidv4() if cookie distinct_id is not found
    }
  } else {
    throw new Error("Unknown environment");
  }
}

export async function getFeatureFlag(
  flagName: string,
): Promise<{ variant: string | undefined; payload: object | undefined }> {
  const { $posthog } = useNuxtApp();
  let variant: string | boolean | undefined;
  let payload: JsonType | string | undefined;

  try {
    const distinctId = getDistinctId(); // Get the distinctId based on the environment

    variant = await $posthog.getFeatureFlag(flagName, distinctId);
    payload = await $posthog.getFeatureFlagPayload(flagName, distinctId);

    if (!payload) {
      payload = ""; // If payload is null or undefined, set it to an empty string
    }
  } catch (error) {
    console.error("Error fetching feature flag:", error);
    variant = undefined; // Set the return value in case of error
  }

  return { variant, payload };
}
